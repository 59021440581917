import Cropper from 'cropperjs'
import { canvasToBlob } from 'blob-util'

export const imgMixin = {
  methods: {
    resizeImg(file, maxWidth) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onload = () => {
          const img = reader.result
          const imgTag = new Image()
          imgTag.onload = (e) => {
            const canvas = document.createElement('canvas')
            // const maxWidth = 500
            const scaleSize = maxWidth / e.target.width
            canvas.width = maxWidth
            canvas.height = e.target.height * scaleSize

            const ctx = canvas.getContext('2d')
            ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height)
            const encodedImg = canvas.toDataURL(e.target, 'image/jpg')

            resolve(encodedImg)
          }
          imgTag.src = img
        }
        reader.readAsDataURL(file)
      })
    },
    base64ToFile(base64str) {
      return new Promise((resolve) => {
        const imgTag = new Image()
        imgTag.onload = async (e) => {
          const canvas = document.createElement('canvas')
          canvas.width = e.target.width
          canvas.height = e.target.height

          const ctx = canvas.getContext('2d')
          ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height)
          const blob = await canvasToBlob(canvas, 'image/jpeg')
          blob.lastModifiedDate = new Date()
          blob.name = 'logo.jpg'
          const outputFile = new File([blob], 'logo.jpg')

          resolve(outputFile)
        }
        imgTag.src = base64str
      })
    },
    initCropper(ref) {
      return new Cropper(ref, {
        zoomable: false,
        scalable: false,
        aspectRatio: 1,
        viewMode: 1
      })
    },
    getCropperOutput(cropper, type) {
      const canvas = cropper.getCroppedCanvas()

      // base64 returns a base64 img
      if (type === 'base64') return canvas.toDataURL('image/jpg')
      // blob returns a promise blob
      else if (type === 'blob') {
        const outputImg = canvasToBlob(canvas, 'image/jpeg')
        return outputImg
      }
    },
    getBase64AndFile(file, maxWidth) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onload = () => {
          const img = reader.result
          const imgTag = new Image()
          imgTag.onload = async (e) => {
            const canvas = document.createElement('canvas')
            // const maxWidth = 500
            const scaleSize = maxWidth / e.target.width
            canvas.width = maxWidth
            canvas.height = e.target.height * scaleSize

            const ctx = canvas.getContext('2d')
            ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height)
            const encodedImg = canvas.toDataURL(e.target, 'image/jpg', 0.7)

            const blob = await canvasToBlob(canvas, 'image/jpeg')
            blob.lastModifiedDate = new Date()
            blob.name = 'logo.jpg'
            const outputFile = new File([blob], 'logo.jpg')

            resolve({ base64: encodedImg, file: outputFile })
          }
          imgTag.src = img
        }
        reader.readAsDataURL(file)
      })
    }
  }
}
