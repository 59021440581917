<template>
  <div class="chat-panel" :style="{ bottom: `${bottom + bottomPlus}px` }">
    <div class="send" @click="handleSendMessage">
      <svg viewBox="0 0 512.001 512.001">
        <path
          d="M507.608,4.395c-4.243-4.244-10.609-5.549-16.177-3.321L9.43,193.872c-5.515,2.206-9.208,7.458-9.42,13.395
			c-0.211,5.936,3.101,11.437,8.445,14.029l190.068,92.181l92.182,190.068c2.514,5.184,7.764,8.455,13.493,8.455
			c0.178,0,0.357-0.003,0.536-0.01c5.935-0.211,11.189-3.904,13.394-9.419l192.8-481.998
			C513.156,15.001,511.851,8.638,507.608,4.395z M52.094,209.118L434.72,56.069L206.691,284.096L52.094,209.118z M302.883,459.907
			l-74.979-154.599l228.03-228.027L302.883,459.907z"
        />
      </svg>
    </div>
    <div class="text-input">
      <base-input
        animate-type="remove"
        label="متن پیام خود را وارد نمایید"
        width="100%"
        v-model="messageText"
        ref="chat-input-ref"
        @keypress.enter="handleSendMessage"
      />
    </div>
    <div class="upload-pic">
      <input
        type="file"
        name="file-input"
        accept="image/*"
        @change="handleFileInput"
      />
      <svg viewBox="0 0 486.3 486.3">
        <path
          d="M395.5,135.8c-5.2-30.9-20.5-59.1-43.9-80.5c-26-23.8-59.8-36.9-95-36.9c-27.2,0-53.7,7.8-76.4,22.5
			c-18.9,12.2-34.6,28.7-45.7,48.1c-4.8-0.9-9.8-1.4-14.8-1.4c-42.5,0-77.1,34.6-77.1,77.1c0,5.5,0.6,10.8,1.6,16
			C16.7,200.7,0,232.9,0,267.2c0,27.7,10.3,54.6,29.1,75.9c19.3,21.8,44.8,34.7,72,36.2c0.3,0,0.5,0,0.8,0h86
			c7.5,0,13.5-6,13.5-13.5s-6-13.5-13.5-13.5h-85.6C61.4,349.8,27,310.9,27,267.1c0-28.3,15.2-54.7,39.7-69
			c5.7-3.3,8.1-10.2,5.9-16.4c-2-5.4-3-11.1-3-17.2c0-27.6,22.5-50.1,50.1-50.1c5.9,0,11.7,1,17.1,3c6.6,2.4,13.9-0.6,16.9-6.9
			c18.7-39.7,59.1-65.3,103-65.3c59,0,107.7,44.2,113.3,102.8c0.6,6.1,5.2,11,11.2,12c44.5,7.6,78.1,48.7,78.1,95.6
			c0,49.7-39.1,92.9-87.3,96.6h-73.7c-7.5,0-13.5,6-13.5,13.5s6,13.5,13.5,13.5h74.2c0.3,0,0.6,0,1,0c30.5-2.2,59-16.2,80.2-39.6
			c21.1-23.2,32.6-53,32.6-84C486.2,199.5,447.9,149.6,395.5,135.8z"
        />
        <path
          d="M324.2,280c5.3-5.3,5.3-13.8,0-19.1l-71.5-71.5c-2.5-2.5-6-4-9.5-4s-7,1.4-9.5,4l-71.5,71.5c-5.3,5.3-5.3,13.8,0,19.1
			c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4l48.5-48.5v222.9c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5V231.5l48.5,48.5
			C310.4,285.3,318.9,285.3,324.2,280z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { imgMixin } from '@mixin/imgMixin'

export default {
  name: 'ChatPanel',
  mixins: [imgMixin],
  emits: ['send-message', 'send-image'],
  data() {
    return {
      windowHeight: window.innerHeight,
      bottom: 50
    }
  },
  methods: {
    handleSendMessage() {
      if (this.messageText.trim() == '')
        return this.$refs['chat-input-ref'].$el.querySelector('input').focus()
      this.$emit('send-message')
      this.$refs['chat-input-ref'].$el.querySelector('input').focus()
    },
    onResize() {
      this.windowHeight = window.innerHeight
    },
    async handleFileInput(e) {
      if (e.target.files && e.target.files.length > 0) {
        // base64
        const uploadedImg = await this.getBase64AndFile(e.target.files[0], 400)
        this.$store.commit('ticket/UPDATE_MESSAGE_IMAGE', uploadedImg)
        this.$emit('send-image')
      }
    }
  },
  created() {
    window.addEventListener('resize', this.onResize)
  },
  unmounted() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    messageText: {
      get() {
        return this.$store.state.ticket.messageText
      },
      set(value) {
        this.$store.commit('ticket/UPDATE_MESSAGE_TEXT', value)
      }
    },
    isIphoneX() {
      return this.$store.getters.isIphoneX
    },
    bottomPlus() {
      return this.isIphoneX ? 20 : 0
    }
  },
  watch: {
    windowHeight(val) {
      const { availHeight } = window.screen

      if (Math.ceil(100 * (val / availHeight)) < 80) {
        this.bottom = 0
        this.$emit('height-change', 'short')
      } else {
        this.bottom = 50
        this.$emit('height-change', 'tall')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-panel {
  background-color: #fff;
  position: fixed;
  width: 100%;
  right: 0;
  left: 0;
  height: 50px;
  padding: 0 10px;
  margin: 0;
  z-index: 10;
  box-shadow: 0 0 8px 1px rgba(#000, 0.1);
  padding-bottom: 5px;
  display: grid;
  grid-template-columns: 40px auto 40px;
  grid-template-rows: 50px;

  .send {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    svg {
      fill: #555;
    }
  }
  .text-input {
    @extend .send;
  }

  .upload-pic {
    @extend .send;
    position: relative;
    overflow: hidden;
    input {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      filter: alpha(opacity=0);
    }
  }

  svg {
    width: 30px;
    height: 30px;
  }
}
</style>
