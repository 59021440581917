<script setup>
import { ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import {
  reqString,
  reqObject,
  validateForms,
  schemaMaker
} from '@valid/JoiValidation'
import { addSupportTicket } from '@service/TicketService'
import BoxTextarea from '@common/BoxTextarea'

const store = useStore()
const router = useRouter()

const DEPARTMENTS = [
  { id: 1, name: 'FINANCIAL', nameFa: 'مالی' },
  { id: 2, name: 'TECHNICAL', nameFa: 'فنی' }
]
const form = reactive({
  department: null,
  title: '',
  message: ''
})
const modals = reactive({
  department: false
})

// form submition
const loading = ref(false)
const isDone = ref(false)
const submit = async () => {
  if (loading.value) return

  const rules = schemaMaker({
    department: reqObject('دپارتمان'),
    title: reqString('عنوان'),
    message: reqString('متن پیام')
  })
  const errors = validateForms(form, rules)
  if (errors && errors.length > 0) {
    for (let error of errors) {
      store.dispatch('addToast', { type: 'error', message: error })
    }
    return
  }

  loading.value = true
  try {
    await addSupportTicket(form)
    store.dispatch('addToast', {
      type: 'success',
      message: 'تیکت با موفقیت ایجاد گردید.'
    })
    isDone.value = true
    setTimeout(() => router.push({ name: 'TicketSupport' }), 1500)
  } catch {
    store.dispatch('addToast', {
      type: 'error',
      message: 'درخواست شما موفقیت‌آمیز نبود.'
    })
  } finally {
    loading.value = false
  }
}
const { department, message, title } = toRefs(form)
</script>

<template>
  <div class="cmp-wrapper">
    <Navbar msg="ایجاد تیکت" />
    <form novalidate class="form-wrapper" @submit.prevent="submit">
      <a class="customer-btn" @click="modals['department'] = true">
        <span>{{ department ? department['nameFa'] : 'انتخاب دپارتمان' }}</span>
        <svg viewBox="0 0 512 512" class="customer-btn__arrow">
          <path
            d="M174.6 252.89l205.16 -205.16c7.2,-7.21 7.2,-18.89 0,-26.1 -7.2,-7.2 -18.88,-7.2 -26.09,0l-221.43 221.44c-7.2,7.2 -7.2,18.88 0,26.09l221.43 221.43c7.33,7.08 19.01,6.87 26.09,-0.46 6.91,-7.15 6.91,-18.48 0,-25.63l-205.13 -205.13 0 -0.03c-0.82,-0.83 -1.33,-1.97 -1.33,-3.23 0,-1.26 0.51,-2.4 1.33,-3.22l-0.03 0z"
          />
        </svg>
      </a>
      <BaseInput
        v-model="title"
        label="عنوان"
        animate-type="decrease"
        width="100%"
        star-label
      />
      <BoxTextarea v-model="message" star-label label="پیام" />
      <div class="credit-buttons">
        <button :disabled="isDone" class="button button--black">
          <span class="button__text">ارسال</span>
          <spinner v-if="loading" />
        </button>
      </div>
    </form>
    <Modal
      :is-open="modals['department']"
      @modalClosed="modals['department'] = false"
      title="انتخاب دپارتمان"
    >
      <div class="available-filter">
        <template v-for="item in DEPARTMENTS" :key="item['id']">
          <div
            class="filter-row"
            @click="
              () => {
                department = item
                modals['department'] = false
              }
            "
          >
            <h5 :class="item.id === department?.id && 'gold'">
              {{ item['nameFa'] }}
            </h5>
          </div>
          <hr />
        </template>
      </div>
    </Modal>
    <FixedFooter />
  </div>
</template>

<style lang="scss" scoped>
.form-wrapper {
  width: 90%;
}
.credit-buttons .button {
  width: 100%;
}

.customer-btn {
  position: relative;
  background-color: #ddd;
  height: 70px;
  padding-bottom: 5px;
  margin: 15px 0;
  width: 100%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    color: #000;
    font-size: 15px;
  }
}
.customer-btn__arrow {
  height: 18px;
  fill: #aaa;
  position: absolute;
  left: 14px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  transform: rotate(-90deg);
}

.available-filter {
  padding: 10px 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .filter-row {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 92%;

    img {
      width: 40px;
      height: 40px;
      border-radius: 5px;
    }
    h5 {
      text-align: center;
      width: 100%;
      color: #111;
      font-size: 16px;
      margin: 5px 0;
      &.gold {
        color: #c69d4e;
      }
    }
  }

  hr {
    width: 92%;
    border-top: 1px solid #aaa;
    margin: 10px 0;
    &:last-child {
      display: none;
    }
  }
}
</style>
