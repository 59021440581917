<template>
  <div class="ticket-wrapper" :style="{ paddingBottom }">
    <TicketNav name="پشتیبانی ویپون" type="support" />
    <div class="background">
      <svg viewBox="0 0 512 512">
        <path
          d="M256 388.99l208.79 -211.84c8.96,24.04 13.94,50.03 14.14,77.17l-222.93 226.18 -222.93 -226.18c0.2,-27.14 5.18,-53.13 14.14,-77.17l208.79 211.84zm0 -265.32l-77.22 -78.34c23.87,-8.87 49.65,-13.75 76.56,-13.83l0.66 0.67 0.66 -0.67c26.91,0.08 52.69,4.96 76.56,13.83l-77.22 78.34zm0 178.42l-178.29 -180.9c12.88,-17.25 28.18,-32.57 45.38,-45.46l132.91 134.85 132.91 -134.85c17.2,12.89 32.5,28.21 45.38,45.46l-178.29 180.9z"
        />
      </svg>
    </div>
    <div class="messages-wrapper" :style="{ minHeight }">
      <Spinner v-if="isLoading" />
      <transition-group
        name="new-message"
        tag="div"
        class="messages-inner"
        v-if="!!messages"
        :appear="false"
      >
        <Observer @intersect="intersected" key="observer" />
        <div
          v-for="item in messages"
          :key="item['id']"
          :class="[
            'message',
            item['response_type'] == 3 ? 'message--send' : 'message--receive'
          ]"
        >
          <img
            v-if="item['response_type'] == 3"
            class="message__profile"
            :src="ticketInfo['storeImage']"
          />
          <img
            v-if="item['response_type'] == 4 && ticketInfo['customerImage']"
            class="message__profile"
            :src="ticketInfo['customerImage']"
          />
          <img
            v-else-if="
              item['response_type'] == 4 && !ticketInfo['customerImage']
            "
            class="message__profile"
            src="@img/user-default.svg"
          />
          <div class="message__text" v-if="item['text']">
            {{ item['text'] }}
            <div class="message__detail">
              <i
                v-if="item['response_type'] == 3"
                :class="{
                  'msg-spinner': item['status'] === 'loading',
                  'msg-fail': item['status'] === 'reject',
                  'msg-check': item['status'] === 'resolve' || !item['status']
                }"
              />
              <span
                class="message__time"
                v-text="getHour(item['created_at'])"
              />
            </div>
          </div>
          <div class="message__image" v-else>
            <img :src="item['image']" class="message__img" />
            <div class="message__detail">
              <i
                v-if="item['response_type'] == 3"
                :class="{
                  'msg-spinner': item['status'] === 'loading',
                  'msg-fail': item['status'] === 'reject',
                  'msg-check': item['status'] === 'resolve' || !item['status']
                }"
              />
              <span
                class="message__time"
                v-text="getHour(item['created_at'])"
              />
            </div>
          </div>
        </div>
      </transition-group>
    </div>
    <ChatPanel
      @sendMessage="handleSendMessage"
      @sendImage="handleSendImage"
      @heightChange="handleHeightChange"
    />
    <FixedFooter />
  </div>
</template>

<script>
import { computed, nextTick, reactive, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { dateMixin } from '@mixin/dateMixin'
import * as TicketService from '@service/TicketService'
import ChatPanel from './subComponents/ChatPanel'
import TicketNav from './subComponents/TicketNav'

export default {
  name: 'TicketShow',
  mixins: [dateMixin],
  props: ['id'],
  components: {
    ChatPanel,
    TicketNav
  },
  setup(props) {
    /**************
     * MAIN ITEMS *
     **************/
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    /*******************
     * GET TICKET INFO *
     *******************/
    const ticketInfo = reactive({
      customerId: null,
      customerName: '',
      customerImage: null,
      storeId: null,
      storeName: '',
      storeImg: null,
      isBlock: false
    })
    const fetchTicketInfo = async (id) => {
      try {
        const { data } = await TicketService.getTicketInfo(id)
        ticketInfo['customerId'] = data['wallet']['id']
        ticketInfo['customerName'] = data['wallet']['name']
        ticketInfo['customerImage'] = data['wallet']['image']
        ticketInfo['storeId'] = data['store']['id']
        ticketInfo['storeName'] = data['store']['first_name']
        ticketInfo['storeImage'] = data['store']['image']
        ticketInfo['isBlock'] = data['block'] == 1
      } catch (ex) {
        console.log(ex)
      }
    }
    fetchTicketInfo(props.id)

    // Reduce the ticketCount by calling homedata
    watchEffect(() => {
      if (ticketInfo['storeId'] && route.query.hasMsg)
        store.dispatch('fetchHomeData')
    })
    /****************
     * GET MESSAGES *
     ****************/
    store.dispatch('fetchMessagesInit', props.id)
    const page = computed(() => store.state.ticket.messagePage)
    const isLoading = computed(() => store.state.ticket.isLoading)

    // Intersection
    const initialSeen = ref(true)
    const intersected = () => {
      if (initialSeen.value) {
        setTimeout(() => (initialSeen.value = false), 3000)
      }
      if (
        initialSeen.value ||
        page.value === 1 ||
        page.value === -1 ||
        isLoading.value
      )
        return
      store.dispatch('fetchMessages', props.id)
    }
    /****************
     * HANDLE BLOCK *
     ****************/
    const handleBlockClicked = async () => {
      try {
        const message = !ticketInfo['isBlock']
          ? 'مشتری با موفقیت بلاک گردید.'
          : 'بلاک مشتری با موفقیت برداشته شد.'
        await TicketService.blockTicket(props.id)
        store.dispatch('addToast', {
          type: 'success',
          message
        })
        router.push({ name: 'Ticket' })
      } catch (ex) {
        store.dispatch('addToast', {
          type: 'error',
          message: 'درخواست شما موفقیت‌آمیز نبود.'
        })
      }
    }
    /****************
     * SEND MESSAGE *
     ****************/
    const messages = computed(() => store.state.ticket.messages)
    const handleSendMessage = () => {
      const message = {
        ticketId: props.id,
        customerId: ticketInfo['customerId'],
        storeId: ticketInfo['storeId']
      }
      store.dispatch('sendMessage', message)
      nextTick(() => {
        window.scrollTo(0, document.body.scrollHeight)
      })
    }
    /**************
     * SEND IMAGE *
     **************/
    const handleSendImage = () => {
      const message = {
        ticketId: props.id,
        customerId: ticketInfo['customerId'],
        storeId: ticketInfo['storeId']
      }
      store.dispatch('sendImage', message)
      nextTick(() => {
        window.scrollTo(0, document.body.scrollHeight)
      })
    }
    // Control page
    const pageLoading = computed(() => store.state.ticket.pageLoading)
    watchEffect(() => {
      if (!pageLoading.value) {
        nextTick(() => window.scrollTo(0, document.body.scrollHeight))
      }
    })

    return {
      messages,
      pageLoading,
      ticketInfo,
      handleBlockClicked,
      handleSendMessage,
      handleSendImage,
      intersected,
      page,
      isLoading
    }
  },
  data() {
    return {
      paddingBottom: 110 + 'px',
      minHeight: 'calc(100vh - 140px)'
    }
  },
  methods: {
    // pick type (sending or receiving)
    handleHeightChange(size) {
      if (size == 'tall') {
        this.paddingBottom = 110 + 'px'
        this.minHeight = 'calc(100vh - 140px)'
      } else if (size == 'short') {
        this.paddingBottom = 60 + 'px'
        this.minHeight = 'calc(100vh - 90px)'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ticket-wrapper {
  width: 100%;
  padding: 30px 0 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;

  .background {
    position: fixed;
    background-color: #eeeeee;
    top: 10px;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: -1;

    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    svg {
      fill: #f8f8f8;
    }
  }
}

.messages-wrapper {
  display: flex;
  width: 100%;
  // 110 bottom + 30 top
  // min-height: calc(100vh - 140px);
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
// just for a v-if wrapper
.messages-inner {
  width: 100%;
  margin: 0;
  padding: 0;
}
/** Message Block */
$img-width: 40px;
$img-side-margin: 5px;
$box-side-margin: 19px;
$tri-width: -8px;
$time-margin: 3px;

.message {
  margin: 15px 0 28px;
  width: 100%;
  min-height: 40px;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
}
.message__profile {
  position: absolute;
  width: $img-width;
  height: $img-width;
  border-radius: 50%;
  top: 4px;
}
.message__text {
  min-width: 55px;
  z-index: auto;
  text-align: right;
  padding: 10px 15px;
  max-width: 60%;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  position: relative;
  border-radius: 10px;
  &::before {
    z-index: 0;
    content: '';
    position: absolute;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    top: 9px;
    width: 0;
    height: 0;
  }
}
.message__image {
  z-index: auto;
  text-align: right;
  padding: 10px 0;
  max-width: 60%;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  position: relative;
  border-radius: 10px;
}
.message__img {
  position: initial;
  display: block;
  border-radius: 15px;
  width: 300px;
  height: auto;
}
.message__detail {
  position: absolute;
  bottom: -20px;
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: flex-end;
  color: rgba(#000000, 0.5);
}
.message__time {
  margin-right: 5px;
}
// Modifiers
.message--send {
  justify-content: flex-start;
}
.message--send .message__text {
  background-color: #fff;
  padding-left: 10px;
  margin-right: $box-side-margin + $img-width;
  box-shadow: 0 0 10px 1px rgba(#000, 0.3);
  &::before {
    right: $tri-width;
    border-left: 12px solid #fff;
  }
}
.message--send .message__image {
  padding-left: 10px;
  margin-right: $box-side-margin + $img-width;
}
.message--send .message__detail {
  left: 0;
}
.message--send .message__profile {
  right: $img-side-margin;
}

.message--receive {
  justify-content: flex-end;
}
.message--receive .message__text {
  background-color: #333;
  color: #eee;
  padding-right: 10px;
  margin-left: $box-side-margin + $img-width;
  &::before {
    left: $tri-width;
    border-right: 12px solid #333;
  }
}
.message--receive .message__image {
  direction: ltr;
  padding-left: 10px;
  margin-left: $box-side-margin + $img-width;
}
.message--receive .message__detail {
  direction: ltr;
  right: $time-margin;
}
.message--receive .message__profile {
  left: $img-side-margin;
}

// sending messages status
.msg-spinner {
  margin: 5px 0 0;
  width: 12px;
  height: 12px;
  position: relative;
  border: 1px solid #777;
  border-bottom-color: transparent;
  border-radius: 100%;
  animation: loader-03 1s linear infinite;
}
@keyframes loader-03 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.msg-check {
  height: 12px;
  width: 6px;
  display: inline-block;
  transform: rotate(45deg);
  border-bottom: 2px solid #00c209;
  border-right: 2px solid #00c209;
}

.msg-fail {
  height: 12px;
  width: 12px;
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: '\d7';
    font-size: 20px;
    color: #d40000;
    text-align: center;
  }
}
</style>
