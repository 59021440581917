<script setup>
import { ref, defineEmits, defineProps } from 'vue'
import { useStore } from 'vuex'

const emit = defineEmits(['block-clicked'])
defineProps({
  name: {
    type: String
  },
  image: {
    type: String
  },
  isBlock: Boolean,
  type: { type: String, default: 'normal' }
})

const flip = ref(false)
// Back button
const store = useStore()
const goBack = () => {
  store.dispatch('goBack')
}
</script>

<template>
  <div class="nav-wrapper">
    <nav class="nav">
      <div class="nav__profile-info">
        <svg
          v-if="type == 'support'"
          viewBox="0 0 512 512"
          class="nav__profile-img"
        >
          <rect fill="#D2D3D5" width="512" height="512" />
          <path
            fill="#E6E7E8"
            d="M256 345.7l140.83 -142.89c6.05,16.22 9.4,33.75 9.54,52.06l-150.37 152.56 -150.37 -152.56c0.14,-18.31 3.49,-35.84 9.54,-52.06l140.83 142.89zm0 -178.96l-52.09 -52.84c16.1,-5.98 33.49,-9.27 51.64,-9.33l0.45 0.45 0.45 -0.45c18.15,0.06 35.53,3.35 51.64,9.33l-52.09 52.84zm0 120.35l-120.26 -122.02c8.69,-11.64 19.01,-21.97 30.61,-30.66l89.65 90.95 89.65 -90.95c11.6,8.69 21.92,19.02 30.61,30.66l-120.26 122.02z"
          />
        </svg>
        <template v-else>
          <img
            class="nav__profile-img"
            v-show="flip"
            :src="image"
            @load="flip = !flip"
          />
          <svg
            class="nav__profile-img"
            style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
            viewBox="0 0 512 512"
            v-if="!flip"
          >
            <circle fill="#606062" cx="256" cy="256" r="256" />
            <path
              fill="#373435"
              d="M201.7 317.66c0,-7.43 -5.22,-13.42 -8.58,-18.06l-11.06 -14.74c-3.33,-4.39 -6.88,-10.74 -9.9,-15.86 -18.6,-31.45 -26.08,-64.92 -23.63,-104.73 1.99,-32.33 19.08,-65.1 47.19,-82.47 52.07,-32.16 134.88,-19.01 160.12,46.69 17.89,46.58 8.74,98.98 -15.73,141.53 -9.12,15.85 -29.58,32.83 -28.81,52.12 0.34,8.53 4.38,12 10.35,15.84 13.28,8.56 50.2,19.39 74,33.64 9.62,5.76 23.16,13.75 31.83,21.68 17.72,16.19 19.6,26.57 22.27,30.03 38.79,-44.88 62.25,-103.36 62.25,-167.33 0,-141.38 -114.62,-256 -256,-256 -141.39,0 -256,114.62 -256,256 0,64.18 23.62,122.85 62.65,167.78 1.67,-3.25 2.68,-6.15 4.75,-9.45 2.22,-3.53 4.12,-5.95 6.62,-8.84 18.93,-21.98 65.14,-46.57 93.07,-59.06 17.47,-7.81 34.61,-9.68 34.61,-28.77z"
            />
          </svg>
        </template>
        <h2 class="nav__profile-name">{{ name }}</h2>
      </div>
      <button
        v-if="type == 'normal'"
        class="nav__btn btn--block"
        type="button"
        @click="emit('block-clicked')"
        v-html="isBlock ? 'UNBLOCK' : '<span style=color:#000>BLOCK</span>'"
      />
      <button class="nav__btn btn--back" type="button" @click="goBack">
        <svg viewBox="0 0 512 512">
          <path
            d="M174.6 252.89l205.16 -205.16c7.2,-7.21 7.2,-18.89 0,-26.1 -7.2,-7.2 -18.88,-7.2 -26.09,0l-221.43 221.44c-7.2,7.2 -7.2,18.88 0,26.09l221.43 221.43c7.33,7.08 19.01,6.87 26.09,-0.46 6.91,-7.15 6.91,-18.48 0,-25.63l-205.13 -205.13 0 -0.03c-0.82,-0.83 -1.33,-1.97 -1.33,-3.23 0,-1.26 0.51,-2.4 1.33,-3.22l-0.03 0z"
          />
        </svg>
      </button>
    </nav>
  </div>
</template>

<style scoped lang="scss">
$nav-height: 60px;

.nav-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.nav {
  position: fixed;
  z-index: 101;
  right: 0;
  left: 0;
  border-radius: 0;
  top: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: $nav-height;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  display: grid;
  grid-template-rows: $nav-height;
  grid-template-columns: [profile-pic] auto 120px [back] 30px 2%;
}
.nav__btn {
  display: block;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.btn--block {
  margin-left: 3px;
  justify-self: end;
  align-self: center;
  height: 28px;
  line-height: 28px;
  text-align: center;
  padding: 1px 10px 0;
  background-color: rgba(#fff, 0.3);
  border-radius: 8px;
  color: #d40000;
  font-weight: bold;
  font-size: 14px;
}
.btn--back {
  grid-column-start: back;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  svg {
    fill: #ddd;
    width: 30px;
    height: 30px;
  }
}
.nav__profile-info {
  grid-column-start: profile-pic;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.nav__profile-img {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  margin-right: 10px;
  margin-bottom: 8px;
  filter: saturate(0.3);
  box-shadow: 0 0 6px 0px rgba($color: #000000, $alpha: 0.3);
}
.nav__profile-name {
  text-align: right;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: white;
  margin-right: 10px;
  margin-bottom: 8px;
  text-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.7);
}
</style>
