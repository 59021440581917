<script setup>
import { ref, defineProps } from 'vue'
defineProps({
  name: String,
  image: String,
  msg: String,
  isBlock: {
    type: Boolean,
    default: false
  },
  hasMsg: {
    type: Boolean,
    default: false
  }
})
const flip = ref(false)
</script>

<template>
  <div :class="['ticket', isBlock && 'ticket--block']">
    <div class="ticket__img">
      <svg viewBox="0 0 512 512">
        <rect fill="#D2D3D5" width="512" height="512" />
        <path
          fill="#E6E7E8"
          d="M256 345.7l140.83 -142.89c6.05,16.22 9.4,33.75 9.54,52.06l-150.37 152.56 -150.37 -152.56c0.14,-18.31 3.49,-35.84 9.54,-52.06l140.83 142.89zm0 -178.96l-52.09 -52.84c16.1,-5.98 33.49,-9.27 51.64,-9.33l0.45 0.45 0.45 -0.45c18.15,0.06 35.53,3.35 51.64,9.33l-52.09 52.84zm0 120.35l-120.26 -122.02c8.69,-11.64 19.01,-21.97 30.61,-30.66l89.65 90.95 89.65 -90.95c11.6,8.69 21.92,19.02 30.61,30.66l-120.26 122.02z"
        />
      </svg>
    </div>
    <div class="ticket__info">
      <h1 class="ticket__name" v-text="name" />
      <p class="ticket__msg" v-text="msg" />
    </div>
    <div class="ticket__status">
      <svg
        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
        viewBox="0 0 600 512"
        :class="['ticket__icon', hasMsg ? 'msg--has' : 'msg--hasnt']"
        v-if="!isBlock"
      >
        <path
          d="M590.93 271.93l-0.01 87.06c0,32.25 -26.2,58.48 -58.46,58.44l-263.67 -0.33 -101.44 90.13c-5.78,5.03 -14.07,6.17 -21.03,2.99 -7.02,-3.19 -11.54,-10.2 -11.54,-17.92l0 -75.13 -67.24 0.25c-32.28,0.11 -58.47,-26.21 -58.47,-58.44l0 -300.54c0,-32.26 26.21,-58.44 58.46,-58.44l186.54 0 55.28 0 223.66 0c32,0.29 57.91,26.36 57.91,58.43l0 87.02 0.01 0 0 126.48z"
        />
      </svg>
      <svg class="ticket__icon" viewBox="0 0 448 448" v-else>
        <path
          fill="#333"
          d="M224,0C100.288,0,0,100.288,0,224s100.288,224,224,224s224-100.288,224-224S347.712,0,224,0z M135.921,90.479
			C162.056,73.223,192.682,64.016,224,64c31.363-0.033,62.035,9.208,88.16,26.56l-221.6,221.6
			C41.871,238.418,62.179,139.169,135.921,90.479z M312.079,357.521C285.944,374.777,255.319,383.984,224,384
			c-31.363,0.033-62.035-9.208-88.16-26.56l221.6-221.6C406.129,209.581,385.821,308.831,312.079,357.521z"
        />
      </svg>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ticket {
  position: relative;
  display: grid;
  grid-template-columns: 80px calc(100% - 140px) 60px;
  align-items: center;
  width: 100%;
  padding-right: 20px;
  height: 110px;
  cursor: pointer;
  background: linear-gradient(to left, #eee 0%, #fff 90%);
}
.ticket__img {
  padding: 0;
  svg {
    border-radius: 100%;
    width: 80px;
    height: 80px;
  }
}
.ticket__info {
  align-self: center;
  padding: 0 6% 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.ticket__name {
  color: #111;
  margin: 0 0 5px;
  text-align: right;
  font-size: 18px;
  font-weight: bold;
}
.ticket__msg {
  margin: 0;
  color: rgba(#111, 0.4);
  font-size: 13px;
  max-width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ticket__status {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.ticket__icon {
  width: 30px;
  height: 30px;
  // border-radius: 50%;
}
.msg--has {
  fill: #c69d4e;
  animation: blinking 1.3s infinite;
}
.msg--hasnt {
  fill: #ddd;
}
.ticket--block {
  background: linear-gradient(to left, #131313 55%, #222 100%);
}
.ticket--block .ticket__name {
  color: #777;
  font-weight: normal;
}
.ticket--block .ticket__msg {
  color: rgba(#777, 0.4);
  font-weight: normal;
}
</style>
