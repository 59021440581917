<template>
  <div :class="['ticket', isBlock && 'ticket--block']">
    <div class="ticket__img">
      <img :src="image" v-show="flip" @load="flip = !flip" />
      <svg
        v-if="!flip"
        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
        viewBox="0 0 512 512"
      >
        <circle fill="#606062" cx="256" cy="256" r="256" />
        <path
          fill="#373435"
          d="M201.7 317.66c0,-7.43 -5.22,-13.42 -8.58,-18.06l-11.06 -14.74c-3.33,-4.39 -6.88,-10.74 -9.9,-15.86 -18.6,-31.45 -26.08,-64.92 -23.63,-104.73 1.99,-32.33 19.08,-65.1 47.19,-82.47 52.07,-32.16 134.88,-19.01 160.12,46.69 17.89,46.58 8.74,98.98 -15.73,141.53 -9.12,15.85 -29.58,32.83 -28.81,52.12 0.34,8.53 4.38,12 10.35,15.84 13.28,8.56 50.2,19.39 74,33.64 9.62,5.76 23.16,13.75 31.83,21.68 17.72,16.19 19.6,26.57 22.27,30.03 38.79,-44.88 62.25,-103.36 62.25,-167.33 0,-141.38 -114.62,-256 -256,-256 -141.39,0 -256,114.62 -256,256 0,64.18 23.62,122.85 62.65,167.78 1.67,-3.25 2.68,-6.15 4.75,-9.45 2.22,-3.53 4.12,-5.95 6.62,-8.84 18.93,-21.98 65.14,-46.57 93.07,-59.06 17.47,-7.81 34.61,-9.68 34.61,-28.77z"
        />
      </svg>
    </div>
    <div class="ticket__info">
      <h1 class="ticket__name" v-text="name" />
      <p class="ticket__msg" v-text="msg" />
    </div>
    <div class="ticket__status">
      <svg
        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
        viewBox="0 0 600 512"
        :class="['ticket__icon', hasMsg ? 'msg--has' : 'msg--hasnt']"
        v-if="!isBlock"
      >
        <path
          d="M590.93 271.93l-0.01 87.06c0,32.25 -26.2,58.48 -58.46,58.44l-263.67 -0.33 -101.44 90.13c-5.78,5.03 -14.07,6.17 -21.03,2.99 -7.02,-3.19 -11.54,-10.2 -11.54,-17.92l0 -75.13 -67.24 0.25c-32.28,0.11 -58.47,-26.21 -58.47,-58.44l0 -300.54c0,-32.26 26.21,-58.44 58.46,-58.44l186.54 0 55.28 0 223.66 0c32,0.29 57.91,26.36 57.91,58.43l0 87.02 0.01 0 0 126.48z"
        />
      </svg>
      <svg class="ticket__icon" viewBox="0 0 448 448" v-else>
        <path
          fill="#333"
          d="M224,0C100.288,0,0,100.288,0,224s100.288,224,224,224s224-100.288,224-224S347.712,0,224,0z M135.921,90.479
			C162.056,73.223,192.682,64.016,224,64c31.363-0.033,62.035,9.208,88.16,26.56l-221.6,221.6
			C41.871,238.418,62.179,139.169,135.921,90.479z M312.079,357.521C285.944,374.777,255.319,383.984,224,384
			c-31.363,0.033-62.035-9.208-88.16-26.56l221.6-221.6C406.129,209.581,385.821,308.831,312.079,357.521z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TicketSingle',
  props: {
    name: String,
    image: String,
    msg: String,
    isBlock: {
      type: Boolean,
      default: false
    },
    hasMsg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      flip: false
    }
  }
}
</script>

<style lang="scss" scoped>
.ticket {
  position: relative;
  display: grid;
  grid-template-columns: 80px calc(100% - 140px) 60px;
  align-items: center;
  width: 100%;
  padding-right: 20px;
  height: 110px;
  cursor: pointer;
  background: linear-gradient(to left, #eee 0%, #fff 90%);
}
.ticket__img {
  padding: 0;
  img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    filter: saturate(0.3);
    box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.1);
  }
}
.ticket__info {
  align-self: center;
  padding: 0 6% 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.ticket__name {
  color: #111;
  margin: 0 0 5px;
  text-align: right;
  font-size: 18px;
  font-weight: bold;
}
.ticket__msg {
  margin: 0;
  color: rgba(#111, 0.4);
  font-size: 13px;
  max-width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ticket__status {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.ticket__icon {
  width: 30px;
  height: 30px;
  // border-radius: 50%;
}
.msg--has {
  fill: #c69d4e;
  animation: blinking 1.3s infinite;
}
.msg--hasnt {
  fill: #ddd;
}
.ticket--block {
  background: linear-gradient(to left, #131313 55%, #222 100%);
}
.ticket--block .ticket__name {
  color: #777;
  font-weight: normal;
}
.ticket--block .ticket__msg {
  color: rgba(#777, 0.4);
  font-weight: normal;
}
</style>
