<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { getSupportTicket } from '@service/TicketService'
import TicketSupportSingle from './subComponents/TicketSupportSingle'

const router = useRouter()
const pageStatus = ref('')
const tickets = ref(null)
const fetchTicket = async () => {
  pageStatus.value = 'loading'
  try {
    const { data } = await getSupportTicket()
    tickets.value = data.results
    pageStatus.value = 'resolved'
  } catch {
    pageStatus.value = 'failed'
  }
}
fetchTicket()
</script>

<template>
  <div class="cmp-wrapper">
    <Navbar msg="ارتباط با پشتیبانی" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading'"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <section class="load-resolved" v-else-if="pageStatus == 'resolved'">
      <div class="credit-buttons">
        <router-link
          :to="{ name: 'TicketSupportNew' }"
          class="button button--black"
        >
          <span class="button__text">ایجاد تیکت جدید</span>
        </router-link>
      </div>
      <h2 class="cmp-title">لیست تیکت ها</h2>
      <div class="cmp-items-wrapper">
        <TicketSupportSingle
          v-for="ticket in tickets"
          :key="ticket['id']"
          :name="ticket['title']"
          :msg="ticket['text']"
          @click="
            router.push({
              name: 'TicketSupportShow',
              params: { id: ticket['id'] }
            })
          "
        />
      </div>
    </section>
    <FailedMode @refresh="fetchTicket" v-else />
    <FixedFooter />
  </div>
</template>

<style lang="scss" scoped></style>
