<script setup>
import { computed, ref, watch, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import dateConvertor from '@composable/dateConvertor'
import TicketSingle from './subComponents/TicketSingle'

const store = useStore()
const { localizeDate, getHour } = dateConvertor()

// Initial api call
const url = '/store/ticket/'
const tickets = computed(() => store.state.ticket.items)
const pageStatus = computed(() => store.state.ticket.pageStatus)

const getTickets = () => {
  if (pageStatus.value == 'loading') return
  store.dispatch('ticket/fetchRecords', { url })
}

// Enter ticket show
const router = useRouter()
const enterTicket = (id, hasMsg) => {
  router.push({ name: 'TicketShow', params: { id }, query: { hasMsg } })
}

// Handle two loadings
const firstLoad = ref(true)
watch(pageStatus, (curr) => {
  if (curr == 'resolved' || curr == 'failed') firstLoad.value = false
})

// Clean the room
onBeforeUnmount(() => store.dispatch('ticket/clearItems'))
</script>

<template>
  <div class="cmp-wrapper">
    <Navbar msg="پیام های مشتریان" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading' && firstLoad"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <FailedMode v-else-if="pageStatus == 'failed'" @refresh="getTickets" />
    <section class="load-resolved" v-else>
      <template v-for="ticket in tickets" :key="ticket['id']">
        <i class="ticket__hr" />
        <TicketSingle
          :name="ticket['wallet']['name']"
          :image="ticket['wallet']['image']"
          :msg="ticket['text']"
          :is-block="ticket['block'] == 1 || ticket['block'] == 3"
          :has-msg="ticket['notification'] == 1"
          @click="enterTicket(ticket['id'], ticket['notification'] == 1)"
        />
      </template>
      <i class="ticket__hr" v-show="tickets.length > 0" />
      <Loader v-show="pageStatus == 'loading' && !firstLoad" />
      <Observer @intersect="getTickets" />
    </section>
    <fixed-footer />
  </div>
</template>

<style lang="scss" scoped>
.ticket__hr {
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
}

// change in common css
.cmp-wrapper {
  background-color: #fff;
}
.load-pending {
  height: calc(100vh - 120px);
}
</style>
